/* You can add global styles to this file, and also import other style files */

@use 'theme/breakpoints';
@use 'theme/variables';
@use 'theme/customizations';

.tofe-box {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 24px;
  margin-bottom: 12px;
  border-radius: 5px;
}

.tofe-box-icon {
  background: #f4f6f9;
  border-radius: 50%;
  width: 3em;
  display: flex;
  height: 3em;
  align-items: center;
  justify-content: center;
}
.tofe-list {
  li {
    display: flex;
    flex-direction: row;
  }
  ng-ui-kit-icon,
  tofe-ui-icon {
    display: inline-block;
    margin-right: 10px;
    font-size: 1.5rem;
    height: 1.5rem;
    margin-bottom: 10px;
  }
  &-inner li {
    margin-left: 30px;
    list-style: disc;
    display: list-item;
  }
}
@media #{breakpoints.$max-w-sm} {
  .tofe-only-desktop {
    display: none;
  }
}

@media #{breakpoints.$min-w-md} {
  .tofe-only-mobile {
    display: none !important;
  }
  .tofe-box {
    padding: 16px;
  }
}

body {
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
}

ng-ui-kit-toast.app-alert {
  display: block;
  .ic1-toast {
    width: auto !important;
  }
}

.no-gap {
  gap: 0;
}
.gap-16 {
  gap: 1rem;
}

/*
 This is a workaround to remove the margin-bottom of the last child of the fieldset
 because there is a ic1-mb-16 class that adds a margin-bottom of 16px
 on each ng-ui-kit form field
 */
.no-gap-form > *:last-child {
  margin-bottom: -16px;
}

.tofe-margin-bottom {
  margin-bottom: 0.75rem;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

app-video-id-otp-input .ic1-overlay {
  z-index: 9999 !important;
}
video-id {
  z-index: 0
}
