:root {
  --tofe-customization-logo-url: url('https://uikit.infocert.it/dist/v8/brands/infocert/Company=InfoCert, Viewport=Desktop, Type=Default.svg');
  --tofe-primary-color: var(--ids-primary);
  --tofe-secondary-color: var(--ids-secondary);
  --tofe-ternary-color: var(--ids-primary);

  --tofe-box-color: var(--ids-grey-1);
  --tofe-box-bg-color: var(--tofe-box-color);
  --tofe-box-white-color: var(--ids-grey-3);
  --tofe-box-white-bg-color: #fff;
  --tofe-box-white-border: 1px solid var(--tofe-box-white-color);

  --tofe-grey-2-color: var(--ids-grey-2);
  --tofe-page-bg-color: #ffffff;
  --tofe-text-color: var(--ids-secondary-dark);
  --tofe-text-font-family: var(--ids-font-family);
  --tofe-text-font-size: 16px;

  --tofe-text-link-color: var(--tofe-ternary-color);
  --tofe-text-link-hover-color: var(--ids-primary);

  --tofe-header-bg-color: #fff;

  --tofe-footer-text-color: var(--ids-secondary-light);
  --tofe-footer-text-font-family: var(--tofe-text-font-family);
  --tofe-footer-text-font-size: var(--tofe-text-font-size);
  --tofe-footer-bg-color: var(--ids-grey-1);
  --tofe-footer-border-top: 0px solid rgb(29, 45, 61);
  --tofe-footer-margin: 0;
  --tofe-footer-padding: 1em;

  --tofe-footer-left-text-color: var(--tofe-text-color);
  --tofe-footer-left-text-font-family: var(--tofe-text-font-family);
  --tofe-footer-left-text-font-size: var(--tofe-text-font-size);

  --tofe-footer-right-text-color: var(--tofe-footer-text-color);
  --tofe-footer-right-text-font-family: var(--tofe-footer-text-font-family);
  --tofe-footer-right-text-font-size: 0.75rem;

  --tofe-footer-right-link-text-color: var(--tofe-text-link-color);
  --tofe-footer-right-link-text-font-size: 0.875rem;
  --tofe-footer-right-link-text-font-family: var(--tofe-text-font-family);
  --tofe-footer-right-link-text-hover-color: var(--tofe-text-link-hover-color);

  --tofe-icon-color: var(--tofe-primary-color);

  --tofe-button-primary-color: #fff;
  --tofe-button-primary-bg-color: var(--tofe-primary-color);
  --tofe-button-primary-color-hover: #fff;
  --tofe-button-primary-bg-color-hover: var(--tofe-primary-color);
  --tofe-button-primary-box-shadow-hover: 0 0.125rem 0.375rem var(--tofe-primary-color);
  --tofe-button-primary-disabled-color: #c2d1d9;
  --tofe-button-primary-disabled-bg-color: var(--ids-revoked);

  --tofe-button-primary-color-focus: #fff;
  --tofe-button-primary-bg-color-focus: var(--ids-primary-dark);
  --tofe-button-primary-box-shadow-focus: none;
  --tofe-button-primary-outline-color-focus: #fff;

  --tofe-step-counter-bg-color: var(--tofe-box-color);
  --tofe-step-counter-color: var(--tofe-text-color);
  --tofe-step-counter-done-bg-color: var(--tofe-ternary-color);
  --tofe-step-counter-done-color: #fff;
  --tofe-step-counter-current-bg-color: #fff;
  --tofe-step-counter-current-color: var(--tofe-text-color);

  --tofe-language-picker-color: var(--tofe-text-color);
  --tofe-language-picker-bg-color: #fff;
  --tofe-language-picker-dropdown-item-bg-color: #fff;
  --tofe-language-picker-dropdown-item-color: var(--tofe-text-color);
  --tofe-language-picker-dropdown-item-hover-bg-color: var(--tofe-box-color);
  --tofe-language-picker-dropdown-item-hover-color: var(--tofe-text-color);
  --tofe-language-picker-dropdown-item-selected-bg-color: #fff;
  --tofe-language-picker-dropdown-item-selected-color: var(--ids-primary-dark);
  --tofe-language-picker-dropdown-item-selected-hover-bg-color: var(--ids-grey-1);
  --tofe-language-picker-dropdown-item-selected-hover-color: var(--ids-secondary-dark);
}
