.page {
  /**
    Customization font
    **/
  background-color: var(--tofe-page-bg-color);
  color: var(--tofe-text-color);
  font-family: var(--tofe-text-font-family);
  font-size: var(--tofe-text-font-size);

  [class^='ic1-brand'].ic1-brand-infocert-default {
    background-image: var(--tofe-customization-logo-url);
  }

  .ic1-btn.ic1-btn-primary {
    background-color: var(--tofe-button-primary-bg-color);
    color: var(--tofe-button-primary-color);

    &:hover {
      background-color: var(--tofe-button-primary-bg-color-hover);
      color: var(--tofe-button-primary-color-hover);
      box-shadow: var(--tofe-button-primary-box-shadow-hover);

      ng-ui-kit-icon {
        & > i {
          color: var(--tofe-button-primary-color-hover);

          .ic1-icon-alt.ic1-icon-outlined,
          .ic1-icon.ic1-icon-outlined {
            color: var(--tofe-button-primary-color-hover);
          }
        }
      }
    }

    &:disabled {
      background-color: var(--tofe-button-primary-disabled-bg-color);
      color: var(--tofe-button-primary-disabled-color);

      ng-ui-kit-icon {
        & > i {
          color: var(--tofe-button-primary-disabled-color);

          .ic1-icon-alt.ic1-icon-outlined,
          .ic1-icon.ic1-icon-outlined {
            color: var(--tofe-button-primary-disabled-color);
          }
        }
      }
    }

    &:focus,
    &:focus:focus {
      background-color: var(--tofe-button-primary-bg-color-focus);
      color: var(--tofe-button-primary-color-focus);
      box-shadow: var(--tofe-button-primary-box-shadow-focus);
      outline-color: var(--tofe-button-primary-outline-color-focus);

      ng-ui-kit-icon {
        & > i {
          color: var(--tofe-button-primary-color-focus);

          .ic1-icon-alt.ic1-icon-outlined,
          .ic1-icon.ic1-icon-outlined {
            color: var(--tofe-button-primary-color-focus);
          }
        }
      }
    }

    ng-ui-kit-icon {
      & > i {
        color: var(--tofe-button-primary-color);

        .ic1-icon-alt.ic1-icon-outlined,
        .ic1-icon.ic1-icon-outlined {
          color: var(--tofe-button-primary-color);
        }
      }
    }
  }

  a {
    color: var(--tofe-text-link-color);

    &:hover {
      color: var(--tofe-text-link-hover-color);
    }
  }

  .ic1-footer-wrapper .ic1-footer {
    background-color: var(--tofe-footer-bg-color);
    padding: var(--tofe-footer-padding);
    margin: var(--tofe-footer-margin);
    border-top: var(--tofe-footer-border-top);

    .ic1-footer-content .ic1-footer-right .ic1-footer-copy,
    .ic1-footer-content {
      .ic1-footer-left {
        color: var(--tofe-footer-left-text-color);
        font-family: var(--tofe-footer-left-text-font-family);
        font-size: var(--tofe-footer-left-text-font-size);
      }

      .ic1-footer-right {
        .ic1-footer-copy {
          color: var(--tofe-footer-right-text-color);
          font-size: var(--tofe-footer-right-text-font-size);
          font-family: var(--tofe-footer-right-text-font-family);
        }

        .ic1-footer-list .ic1-footer-list-item {
          a {
            color: var(--tofe-footer-right-link-text-color);
            font-size: var(--tofe-footer-right-link-text-font-size);
            font-family: var(--tofe-footer-right-link-text-font-family);

            &:hover {
              color: var(--tofe-footer-right-link-text-hover-color);
            }
          }
        }
      }
    }
  }

  .ic1-header-wrapper .ic1-header {
    background-color: var(--tofe-header-bg-color);
  }

  .ic1-language,
  .ic1-language-container {
    color: var(--tofe-language-picker-color);
    background-color: var(--tofe-language-picker-bg-color);

    & > i {
      color: var(--tofe-language-picker-color);
      background-color: var(--tofe-language-picker-bg-color);
    }

    & .ic1-dropdown {
      background-color: var(--tofe-language-picker-bg-color);

      & ul.ic1-dropdown-list li.ic1-dropdown-item {
        background-color: var(--tofe-language-picker-dropdown-item-bg-color);
        color: var(--tofe-language-picker-dropdown-item-color);

        &:hover {
          background-color: var(--tofe-language-picker-dropdown-item-hover-bg-color);
          color: var(--tofe-language-picker-dropdown-item-hover-color);
        }

        &[class*='selected'] {
          background-color: var(--tofe-language-picker-dropdown-item-selected-bg-color);
          color: var(--tofe-language-picker-dropdown-item-selected-color);

          &:hover {
            background-color: var(--tofe-language-picker-dropdown-item-selected-hover-bg-color);
            color: var(--tofe-language-picker-dropdown-item-selected-hover-color);
          }
        }
      }
    }
  }

  .ic1-step-counter div[class*='ic1-step'] {
    &.ic1-step {
      background-color: var(--tofe-step-counter-bg-color);
      color: var(--tofe-step-counter-color);
    }

    &.ic1-step-done,
    &.ic1-step-complete.ic1-step-current {
      background-color: var(--tofe-step-counter-done-bg-color);
      color: var(--tofe-step-counter-done-color);

      ng-ui-kit-icon {
        & > i {
          color: var(--tofe-step-counter-done-color);

          .ic1-icon-alt.ic1-icon-outlined,
          .ic1-icon.ic1-icon-outlined {
            color: var(--tofe-step-counter-done-color);
          }
        }
      }
    }

    &.ic1-step-current {
      background-color: var(--tofe-step-counter-current-bg-color);
      color: var(--tofe-step-counter-current-color);
    }
  }
}

.tofe-box {
  background-color: var(--tofe-box-bg-color);

  &.white {
    border: var(--tofe-box-white-border);
    background-color: var(--tofe-box-white-bg-color);
  }

  ng-ui-kit-icon {
    & > i {
      .ic1-icon-alt.ic1-icon-outlined,
      .ic1-icon.ic1-icon-outlined {
        color: var(--tofe-icon-color);
      }
    }
  }
}
